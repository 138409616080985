import React, { Component } from 'react'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import axios from 'axios';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import secureLocalStorage from 'react-secure-storage';
import '../../css/onlinetable.css';
import { withTranslation } from 'react-i18next';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { createPopper } from '@popperjs/core';
import Select from "react-select";
import { Link } from 'react-router-dom';
import { forEach } from 'jszip';
import styles from './OnlineCalls.module.scss';
import moment from 'moment';
import { ThreeSixtySharp } from '@material-ui/icons';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
class OnlineTable extends Component {
    constructor(props){
        super(props);
        this.state = { 
            names: props.names, names1: props.names1, allowed: [],teams:[],
            user:"",dst:"",prefix:"", options:[], transferto:"", brands: ["all"], brandsRender:[],
    }
    }
    componentDidMount(){
        axios.get('https://www.pbxdiamondcall.com:8081/teams').then((res)=>{
            this.state.teams = res.data;
        }).catch((err)=>console.log(err));
        axios.post('https://www.pbxdiamondcall.com:8081/agents/onlineaccess', {
            ui_id:JSON.parse(secureLocalStorage.getItem('user')).user_info_id,
            company_id:JSON.parse(secureLocalStorage.getItem('user')).company_id
        }).then((res)=>{
                this.state.allowed = res.data;
        }).catch((err)=>console.log(err))
        setInterval(()=>{
        this.setState({ names: this.props.names, names1: secureLocalStorage.getItem('names1')});
        },1000)
        if(this.state.names.length>0)
        for (let index = 0; index < JSON.parse(this.state.names).length; index++) {
            const element = JSON.parse(this.state.names)[index]
            if(element["sip_user"].split('_').length===3){
            if(!this.state.brands.includes(element["sip_user"].split('_')[element["sip_user"].split('_').length-1]))
            this.state.brands.push(element["sip_user"].split('_')[element["sip_user"].split('_').length-1]);
            }
        }
        if(this.state.names1.length>0)
        for (let index = 0; index < JSON.parse(this.state.names1).length; index++) {
            const element = JSON.parse(this.state.names1)[index];
            if(element["sip_user"].split('_').length===3){
            if(!this.state.brands.includes(element["sip_user"].split('_')[element["sip_user"].split('_').length-1]))
            this.state.brands.push(element["sip_user"].split('_')[element["sip_user"].split('_').length-1]);
            }
        }
        for (let index = 0; index < this.state.brandsRender.length; index++) {
            const element = this.state.brandsRender[index];
            document.getElementById(element).classList.toggle('btn-primary');
            console.log(document.getElementById(element).classList)
        }
        $('#tablecalls tbody').on('click', 'tr', function() {
            $('#tablecalls tbody > tr').removeClass('row_selected');
            $(this).addClass('row_selected');
        });
    }

   
    handleEnd(uuid,server){
        axios.post('https://www.pbxdiamondcall.com:8081/end', {
            uuid,
            server
        })
        .then((res)=>console.log(res))
        .catch((err)=>console.log(err))
        this.refresh();
    };
    handleTransfer=(uuid,server,user,dest)=>{
        console.log(uuid,server,user,dest);
        axios.post('https://www.pbxdiamondcall.com:8081/agents/getTeammates', {
            user
        })
        .then((res)=>{
            console.log(res.data)
            var arr = [];
            res.data.forEach((element, idx) => {
                arr.push({value:element.Id,label:`${element.Login}`});
            });
            this.setState(prev=>{
                return {
                    ...prev,
                    name:user,
                    dst:dest.slice(3),
                    prefix:dest.slice(0,3),
                    options:arr
                }    
            })
        })
        .catch((err)=>console.log(err))
        
        
        console.log(this.state);

    }
    transfer = (uuid) => {
        axios.post('https://www.pbxdiamondcall.com:8081/transfer/checkout',{
            user:JSON.parse(secureLocalStorage.getItem("user")).login,
            uuid
        }).then((res)=>{

        }).catch((err)=>{

        })
    }
    refresh=()=>{
        this.setState({ names: secureLocalStorage.getItem('names'), names1: secureLocalStorage.getItem('names1')})
        console.log(secureLocalStorage.getItem('names'));
        // $('#table').DataTable().clear().draw();
        // $('#table').DataTable().rows.add(this.names);
        // $('#table').DataTable().rows.add(this.names1);
        // $('#table').DataTable().columns.adjust().draw();
    };
    handleChoose=(e)=>{
        this.setState(prev=>{
            return {
                ...prev,
                transferto:e.value
            }
        })
    }
    handleClose(){
        this.setState(prev=>{
            return {
                ...prev,
                transferto:""
            }
        })
    }
    showTable = () => {
        const { t,endedCalls } = this.props;
        console.log(this.state.teams)
            try {
                return JSON.parse(this.state.names).concat(endedCalls).sort((a,b)=>a["duration"]>b["duration"]?-1:1).map((msg,idx)=>(
                    msg["dst"].match(/^(\d{12,})$/)!=null ?
                    JSON.parse(secureLocalStorage.getItem('user')).role_id !== 1 ?
                    this.state.allowed.some(us=>us.Login===msg["sip_user"])?
                    msg["dst"].substring(0,3)!=="810"?
                    <tr key={msg['uuid']} style={{fontWeight:"bold"}}>
                         {/* <td>{msg["server"]}</td> */}
                        <td>{`${moment.parseZone(new Date(msg['time'])).local().format('YYYY-MM-DD HH:mm:ss')}`}</td>
                        <td>{this.state.teams?.find((val)=>val.id == msg["team"])?.title}</td>
                        <td style={{textAlign:"center"}}>{msg["sip_user"]}</td>
                        <td style={{textAlign:"center"}}>{msg["dst"].substring(0,3)}</td>
                        <td style={{textAlign:"center"}}>{msg["dst"].substring(3)}</td>
                        <td style={{textAlign:"center"}}>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                        :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                        : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                         <td>
                            {msg["status"] === 'Initiated' ? (
                                <button style={{
                                    width: "5vw", padding: "0.3vw 0.6vh", fontWeight: "600", fontFamily: "'Ubuntu',sans-serif", color: "#ffc95e",
                                    border: "1px solid #ffc95e", borderRadius: "0.4vh", textAlign: "center", userSelect: "none"
                                }} disabled={true}>
                                    {t("onlineRinging")}
                                </button>
                            ) : msg["status"] === 'Call Ended' ? (
                                <button style={{
                                    width: "5vw", padding: "0.3vw 0.6vh", fontWeight: "600", fontFamily: "'Ubuntu',sans-serif", color: "#FF4861",
                                    border: "1px solid #FF4861", borderRadius: "0.4vh", textAlign: "center", userSelect: "none"
                                }} disabled={true}>
                                    {"Call Ended"}
                                </button>
                            ) : (
                                <button style={{
                                    width: "5vw", padding: "0.3vw 0.6vh", fontWeight: "600", fontFamily: "'Ubuntu',sans-serif", color: "#24D6A3",
                                    border: "1px solid #24D6A3", borderRadius: "0.4vh", textAlign: "center", userSelect: "none"
                                }} disabled={true}>
                                    {t("onlineInCall")}
                                </button>
                            )}
                        </td>
                        <td>
                            {
                                msg["status"] !== 'Call Ended' &&
                                <div style={{dispaly:"flex",alignItems:"center",justifyContent:'center',width:"100%"}}>
                                    <Link  to={`callto:77${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-headphones fa-xl"  data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipListen")}></i>
                                            <Tooltip id="tooltip" />
                                    </button></Link>
                                    <Link  to={`callto:88${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-headset fa-xl" id="sufler" data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipSufl")}></i>
                                            <Tooltip id="tooltip" />
                                    </button>
                                    </Link>
                                    <Link  to={`callto:99${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                    
                                        ><i className="fa-solid fa-users fa-xl" id="conference" data-tooltip-id="tooltip" data-tooltip-content={"Create conference"}></i>
                                        <Tooltip id="tooltip" />
                                </button>
                                    </Link><button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-phone-slash fa-xl" onClick={()=>this.handleEnd(msg["uuid"],msg["server"])} 
                                            id="end" data-tooltip-id="tooltip" data-tooltip-content={"End call"}></i>
                                            <Tooltip id="tooltip" />
                                    </button>        
                                    <Link  to={`callto:66${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                            border:2,
                                            borderRadius:13,
                                            width:30,
                                            height:30,
                                            margin:3,
                                            padding:1,
                                            color:"#d3d3d2"
                                            }}
                                            onClick={()=>{this.transfer(msg['uuid'])}}
                                            // data-bs-toggle="modal" data-bs-target="#modalTop"
                                            >
                                                <i className="fa-solid fa-right-left fa-xl" 
                                                id="transfer"></i>
                                                <Tooltip id="tooltip" />
                                        </button> 
                                    </Link>
                                </div>

                            }
                        </td>
                    </tr>
                    :null
                    :null
                    :
                    msg["dst"].substring(0,3)!=="810"?
                    !this.state.brandsRender.includes("all")?
                    this.state.brandsRender.includes(msg["sip_user"].split('_')[2])?
                    <tr key={msg['uuid']} style={{fontWeight:"bold"}}>
                     {/* <td>{msg["server"]}</td> */}
                    <td>{`${new Date(msg["time"]).getDate()}-${(new Date(msg["time"]).getMonth() + 1).toString().padStart(2, '0')}-${new Date(msg["time"]).getFullYear()} ${new Date(msg["time"]).getHours().toString().padStart(2, '0')}:${new Date(msg["time"]).getMinutes().toString().padStart(2, '0')}:${new Date(msg["time"]).getSeconds().toString().padStart(2, '0')}`}</td>
                    <td>{this.state.teams?.find((val)=>val.id == msg["team"])?.title}</td>
                    <td style={{textAlign:"center"}}>{msg["sip_user"]}</td>
                    <td style={{textAlign:"center"}}>{msg["dst"].substring(0,3)}</td>
                    <td style={{textAlign:"center"}}>{msg["dst"].substring(3)}</td>
                    <td style={{textAlign:"center"}}>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                        :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                        : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                    
                    <td>
                            {msg["status"] === 'Initiated' ? (
                                <button style={{
                                    width: "5vw", padding: "0.3vw 0.6vh", fontWeight: "600", fontFamily: "'Ubuntu',sans-serif", color: "#ffc95e",
                                    border: "1px solid #ffc95e", borderRadius: "0.4vh", textAlign: "center", userSelect: "none"
                                }} disabled={true}>
                                    {t("onlineRinging")}
                                </button>
                            ) : msg["status"] === 'Call Ended' ? (
                                <button style={{
                                    width: "5vw", padding: "0.3vw 0.6vh", fontWeight: "600", fontFamily: "'Ubuntu',sans-serif", color: "#FF4861",
                                    border: "1px solid #FF4861", borderRadius: "0.4vh", textAlign: "center", userSelect: "none"
                                }} disabled={true}>
                                    {"Call Ended"}
                                </button>
                            ) : (
                                <button style={{
                                    width: "5vw", padding: "0.3vw 0.6vh", fontWeight: "600", fontFamily: "'Ubuntu',sans-serif", color: "#24D6A3",
                                    border: "1px solid #24D6A3", borderRadius: "0.4vh", textAlign: "center", userSelect: "none"
                                }} disabled={true}>
                                    {t("onlineInCall")}
                                </button>
                            )}
                        </td>
                        <td>
                            {
                                msg["status"] !== 'Call Ended' &&
                                <div style={{dispaly:"flex",alignItems:"center",justifyContent:'center',width:"100%"}}>
                                    <Link  to={`callto:77${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-headphones fa-xl"  data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipListen")}></i>
                                            <Tooltip id="tooltip" />
                                    </button></Link>
                                    <Link  to={`callto:88${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-headset fa-xl" id="sufler" data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipSufl")}></i>
                                            <Tooltip id="tooltip" />
                                    </button>
                                    </Link>
                                    <Link  to={`callto:99${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                    
                                        ><i className="fa-solid fa-users fa-xl" id="conference" data-tooltip-id="tooltip" data-tooltip-content={"Create conference"}></i>
                                        <Tooltip id="tooltip" />
                                </button>
                                    </Link><button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-phone-slash fa-xl" onClick={()=>this.handleEnd(msg["uuid"],msg["server"])} 
                                            id="end" data-tooltip-id="tooltip" data-tooltip-content={"End call"}></i>
                                            <Tooltip id="tooltip" />
                                    </button>        
                                    <Link  to={`callto:66${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                            border:2,
                                            borderRadius:13,
                                            width:30,
                                            height:30,
                                            margin:3,
                                            padding:1,
                                            color:"#d3d3d2"
                                            }}
                                            onClick={()=>{this.transfer(msg['uuid'])}}
                                            // data-bs-toggle="modal" data-bs-target="#modalTop"
                                            >
                                                <i className="fa-solid fa-right-left fa-xl" 
                                                id="transfer"></i>
                                                <Tooltip id="tooltip" />
                                        </button> 
                                    </Link>
                                </div>

                            }
                        </td>
                    </tr>
                :null
                    :<tr key={msg['uuid']} style={{fontWeight:"bold"}}>
                    {/* <td>{msg["server"]}</td> */}
                    <td>{`${new Date(msg["time"]).getDate()}-${(new Date(msg["time"]).getMonth() + 1).toString().padStart(2, '0')}-${new Date(msg["time"]).getFullYear()} ${new Date(msg["time"]).getHours().toString().padStart(2, '0')}:${new Date(msg["time"]).getMinutes().toString().padStart(2, '0')}:${new Date(msg["time"]).getSeconds().toString().padStart(2, '0')}`}</td>
                    <td>{this.state.teams?.find((val)=>val.id == msg["team"])?.title}</td>
                    <td style={{textAlign:"center"}}>{msg["sip_user"]}</td>
                    <td style={{textAlign:"center"}}>{msg["dst"].substring(0,3)}</td>
                    <td style={{textAlign:"center"}}>{msg["dst"].substring(3)}</td>
                    <td style={{textAlign:"center"}}>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                        :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                        : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                    <td>
                                {msg["status"] === 'Initiated' ? (
                                    <button style={{
                                        width: "5vw", padding: "0.3vw 0.6vh", fontWeight: "600", fontFamily: "'Ubuntu',sans-serif", color: "#ffc95e",
                                        border: "1px solid #ffc95e", borderRadius: "0.4vh", textAlign: "center", userSelect: "none"
                                    }} disabled={true}>
                                        {t("onlineRinging")}
                                    </button>
                                ) : msg["status"] === 'Call Ended' ? (
                                    <button style={{
                                        width: "5vw", padding: "0.3vw 0.6vh", fontWeight: "600", fontFamily: "'Ubuntu',sans-serif", color: "#FF4861",
                                        border: "1px solid #FF4861", borderRadius: "0.4vh", textAlign: "center", userSelect: "none"
                                    }} disabled={true}>
                                        {"Call Ended"}
                                    </button>
                                ) : (
                                    <button style={{
                                        width: "5vw", padding: "0.3vw 0.6vh", fontWeight: "600", fontFamily: "'Ubuntu',sans-serif", color: "#24D6A3",
                                        border: "1px solid #24D6A3", borderRadius: "0.4vh", textAlign: "center", userSelect: "none"
                                    }} disabled={true}>
                                        {t("onlineInCall")}
                                    </button>
                                )}
                            </td>
                    
                            <td>
                                {
                                    msg["status"] !== 'Call Ended' &&
                                    <div style={{dispaly:"flex",alignItems:"center",justifyContent:'center',width:"100%"}}>
                                        <Link  to={`callto:77${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                            border:2,
                                            borderRadius:13,
                                            width:30,
                                            height:30,
                                            margin:3,
                                            padding:1,
                                            color:"#d3d3d2"
                                            }}
                                            >
                                                <i className="fa-solid fa-headphones fa-xl"  data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipListen")}></i>
                                                <Tooltip id="tooltip" />
                                        </button></Link>
                                        <Link  to={`callto:88${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                            border:2,
                                            borderRadius:13,
                                            width:30,
                                            height:30,
                                            margin:3,
                                            padding:1,
                                            color:"#d3d3d2"
                                            }}
                                            >
                                                <i className="fa-solid fa-headset fa-xl" id="sufler" data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipSufl")}></i>
                                                <Tooltip id="tooltip" />
                                        </button>
                                        </Link>
                                        <Link  to={`callto:99${msg["sip_user"]}`}>
                                            <button className='btn' style={{
                                            border:2,
                                            borderRadius:13,
                                            width:30,
                                            height:30,
                                            margin:3,
                                            padding:1,
                                            color:"#d3d3d2"
                                            }}
                                        
                                            ><i className="fa-solid fa-users fa-xl" id="conference" data-tooltip-id="tooltip" data-tooltip-content={"Create conference"}></i>
                                            <Tooltip id="tooltip" />
                                    </button>
                                        </Link><button className='btn' style={{
                                            border:2,
                                            borderRadius:13,
                                            width:30,
                                            height:30,
                                            margin:3,
                                            padding:1,
                                            color:"#d3d3d2"
                                            }}
                                            >
                                                <i className="fa-solid fa-phone-slash fa-xl" onClick={()=>this.handleEnd(msg["uuid"],msg["server"])} 
                                                id="end" data-tooltip-id="tooltip" data-tooltip-content={"End call"}></i>
                                                <Tooltip id="tooltip" />
                                        </button>        
                                        <Link  to={`callto:66${msg["sip_user"]}`}>
                                            <button className='btn' style={{
                                                border:2,
                                                borderRadius:13,
                                                width:30,
                                                height:30,
                                                margin:3,
                                                padding:1,
                                                color:"#d3d3d2"
                                                }}
                                                onClick={()=>{this.transfer(msg['uuid'])}}
                                                // data-bs-toggle="modal" data-bs-target="#modalTop"
                                                >
                                                    <i className="fa-solid fa-right-left fa-xl" 
                                                    id="transfer"></i>
                                                    <Tooltip id="tooltip" />
                                            </button> 
                                        </Link>
                                    </div>

                                }
                            </td>
                    </tr>
                :null
                :null
                ))
            } 
            catch (e) {
                console.log(e.message);
            }
    };
    showTable1 = () => {
        const { t } = this.props;
            try {
                return JSON.parse(this.state.names1).sort((a,b)=>a["duration"]>b["duration"]?-1:1).map((msg,idx)=>(
                    msg["dst"].match(/^(\d{14,})$/)!=null ?
                    JSON.parse(secureLocalStorage.getItem('user')).role_id !== 1 ?
                    this.state.allowed.some(us=>us.Login===msg["sip_user"])?
                    msg["dst"].substring(0,3)!=="810"?
                    <tr key={msg['uuid']} style={{fontWeight:"bold"}}>
                         {/* <td>{msg["server"]}</td> */}
                        <td>{`${new Date(msg["time"]).getDate()}-${(new Date(msg["time"]).getMonth() + 1).toString().padStart(2, '0')}-${new Date(msg["time"]).getFullYear()} ${new Date(msg["time"]).getHours().toString().padStart(2, '0')}:${new Date(msg["time"]).getMinutes().toString().padStart(2, '0')}:${new Date(msg["time"]).getSeconds().toString().padStart(2, '0')}`}</td>
                        <td>{this.state.teams?.find((val)=>val.id == msg["team"])?.title}</td>
                    <td>{msg["sip_user"]}</td>
                        <td>{msg["dst"].substring(0,3)}</td>
                        <td>{msg["dst"].substring(3)}</td>
                        <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                        :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                        : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                        <td>{msg["status"]=='Initiated'
                            ?
                            <button style={{
                                width:"5vw",padding:"0.3vw 0.6vh",fontWeight:"600", fontFamily:"'Ubuntu',sans-serif",color:"#ffc95e",
                                border:"1px solid #ffc95e", borderRadius:"0.4vh", textAlign:"center",userSelect:"none"
                            }} disabled={true}>
                                    {t("onlineRinging")}
                            </button>
                            :<button style={{
                                width:"5vw",padding:"0.3vw 0.6vh",fontWeight:"600", fontFamily:"'Ubuntu',sans-serif",color:"#24D6A3",
                                border:"1px solid #24D6A3", borderRadius:"0.4vh", textAlign:"center",userSelect:"none"
                            }} disabled={true}>
                            {t("onlineInCall")}
                    </button>}
                        </td>
                        <td>
                            <div>
                                <Link  to={`callto:77${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-headphones fa-xl" data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipListen")}></i>
                                            <Tooltip id="tooltip" />
                                    </button>
                                </Link>
                                <Link  to={`callto:88${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-headset fa-xl" id="sufler" data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipSufl")}></i>
                                            <Tooltip id="tooltip" />
                                    </button>
                                </Link>
                                <Link  to={`callto:99${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        
                                        ><i className="fa-solid fa-users fa-xl" id="conference" data-tooltip-id="tooltip" data-tooltip-content={"Create conference"}></i>
                                        <Tooltip id="tooltip" />
                                    </button>
                                </Link><button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-phone-slash fa-xl" onClick={()=>this.handleEnd(msg["uuid"],msg["server"])} 
                                            id="end" data-tooltip-id="tooltip" data-tooltip-content={"End call"}></i>
                                            <Tooltip id="tooltip" />
                                    </button>
                                
                                    <Link  to={`callto:66${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                            border:2,
                                            borderRadius:13,
                                            width:30,
                                            height:30,
                                            margin:3,
                                            padding:1,
                                            color:"#d3d3d2"
                                            }}
                                            onClick={()=>{this.transfer(msg['uuid'])}}
                                            // data-bs-toggle="modal" data-bs-target="#modalTop"
                                            >
                                                <i className="fa-solid fa-right-left fa-xl" 
                                                id="transfer"></i>
                                                <Tooltip id="tooltip" />
                                        </button> 
                                    </Link>
                            </div>
                        </td>
                    </tr>
                    :null
                    :null
                    :
                    msg["dst"].substring(0,3)!=="810"?
                    this.state.brandsRender.includes(msg["sip_user"].split('_')[2])?
                    <tr key={msg['uuid']} style={{fontWeight:"bold"}}>
                     {/* <td>{msg["server"]}</td> */}
                    <td>{`${new Date(msg["time"]).getDate()}-${(new Date(msg["time"]).getMonth() + 1).toString().padStart(2, '0')}-${new Date(msg["time"]).getFullYear()} ${new Date(msg["time"]).getHours().toString().padStart(2, '0')}:${new Date(msg["time"]).getMinutes().toString().padStart(2, '0')}:${new Date(msg["time"]).getSeconds().toString().padStart(2, '0')}`}</td>
                    <td>{this.state.teams?.find((val)=>val.id == msg["team"])?.title}</td>
                    <td>{msg["sip_user"]}</td>
                    <td>{msg["dst"].substring(0,3)}</td>
                    <td>{msg["dst"].substring(3)}</td>
                    <td>{parseInt(msg["duration"])>3600?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(11, 19)
                                        :parseInt(msg["duration"]) < 60?new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(17, 19) + ` ${t("onlineSeconds")}`
                                        : new Date(parseInt(msg["duration"]) * 1000).toISOString().substring(14, 19)}</td>
                    <td>
                    {msg["status"]=='Initiated'
                            ?
                            <button style={{
                                width:"5vw",padding:"0.3vw 0.6vh",fontWeight:"600", fontFamily:"'Ubuntu',sans-serif",color:"#ffc95e",
                                border:"1px solid #ffc95e", borderRadius:"0.4vh", textAlign:"center",userSelect:"none"
                            }} disabled={true}>
                                    {t("onlineRinging")}
                            </button>
                            :<button style={{
                                width:"5vw",padding:"0.3vw 0.6vh",fontWeight:"600", fontFamily:"'Ubuntu',sans-serif",color:"#24D6A3",
                                border:"1px solid #24D6A3", borderRadius:"0.4vh", textAlign:"center",userSelect:"none"
                            }} disabled={true}>
                            {t("onlineInCall")}
                    </button>
                        }
                    </td>
                    <td>
                            <div>
                                <Link  to={`callto:77${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-headphones fa-xl" data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipListen")}></i>
                                            <Tooltip id="tooltip" />
                                    </button>
                                </Link>
                                <Link  to={`callto:88${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-headset fa-xl" id="sufler" data-tooltip-id="tooltip" data-tooltip-content={t("onlineTooltipSufl")}></i>
                                            <Tooltip id="tooltip" />
                                    </button>
                                </Link>
                                <Link  to={`callto:99${msg["sip_user"]}`}>
                                    <button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        
                                        ><i className="fa-solid fa-users fa-xl" id="conference" data-tooltip-id="tooltip" data-tooltip-content={"Create conference"}></i>
                                        <Tooltip id="tooltip" />
                                    </button>
                                </Link><button className='btn' style={{
                                        border:2,
                                        borderRadius:13,
                                        width:30,
                                        height:30,
                                        margin:3,
                                        padding:1,
                                        color:"#d3d3d2"
                                        }}
                                        >
                                            <i className="fa-solid fa-phone-slash fa-xl" onClick={()=>this.handleEnd(msg["uuid"],msg["server"])} 
                                            id="end" data-tooltip-id="tooltip" data-tooltip-content={"End call"}></i>
                                            <Tooltip id="tooltip" />
                                    </button>
                                
                                    <Link  to={`callto:66${msg["sip_user"]}`}>
                                        <button className='btn' style={{
                                            border:2,
                                            borderRadius:13,
                                            width:30,
                                            height:30,
                                            margin:3,
                                            padding:1,
                                            color:"#d3d3d2"
                                            }}
                                            onClick={()=>{this.transfer(msg['uuid'])}}
                                            // data-bs-toggle="modal" data-bs-target="#modalTop"
                                            >
                                                <i className="fa-solid fa-right-left fa-xl" 
                                                id="transfer"></i>
                                                <Tooltip id="tooltip" />
                                        </button> 
                                    </Link>
                            </div>
                    </td>
                </tr>
                :null
                :null
                :null
                ))
            } 
            catch (e) {
                console.log(e.message);
            }
            };
    render(){
        const { t } = this.props;
        return(
            <div className="table-responsive p-0 pb-2"  style={{minHeight:"500px", padding:10}}>
                <div className="modal fade" data-bs-backdrop="static" id="modalTop" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-cente#FF4861">
                        <form className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTopTitle">Transfer call</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>this.handleClose()}/>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col mb-3">
                                    <label htmlFor="nameSlideTop" className="form-label">From user</label>
                                    <input type="text" id="nameSlideTop" className="form-control" disabled={true} value={this.state.name} />
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col mb-0">
                                    <label htmlFor="emailSlideTop" className="form-label">Client number</label>
                                    <input type="text" id="emailSlideTop" className="form-control"disabled={true} value={this.state.dst}  />
                                </div>
                                <div className="col mb-0">
                                    <label htmlFor="dobSlideTop" className="form-label">Prefix</label>
                                    <input type="text" id="dobSlideTop" className="form-control" disabled={true} value={this.state.prefix}  />
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col mb-3">
                                <Select options={this.state.options} 
                                    onChange={(e)=>this.handleChoose(e)}
                                    placeholder='Select agent' id='prefixupdLb'
                                    styles={{
                                        control:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                            borderColor:"#444663"
                                        }),
                                        option: (styles, { isFocused, isSelected }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: isSelected ? "#404152" : isFocused ? "#535463" : undefined
                                            };
                                        },
                                        singleValue:(baseStyles,state)=>({
                                            ...baseStyles,
                                            color:"#b8b8cd"
                                        }),
                                        placeholder:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd"
                                        }),
                                        menuList:(baseStyles,state)=>({
                                            ...baseStyles,
                                            background:"#444663",
                                            color:"#b8b8cd",
                                        }),
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-label-secondary" data-bs-dismiss="modal" onClick={()=>this.handleClose()}>Close</button>
                            <button type="button" className="btn btn-primary" disabled={this.state.transferto!==""?false:true}>Transfer</button>
                        </div>
                        </form>
                    </div>
                </div>
                {
                    JSON.parse(secureLocalStorage.getItem('user')).role_id !== 1 
                    ?
                    null
                    :
                    <div className="nav-align-top">
                                <ul className="nav nav-tabs">
                                {
                                this.state.brands.map((val,ind)=>{
                                    return <li className="nav-item" key={ind}>
                                    <button type="button" id={val} className={styles.nav_item}
                                    role="tab" style={{margin:5}} 
                                    data-bs-toggle="tab" data-bs-target="#navs-top-home" 
                                    aria-controls="navs-top-home" aria-selected="true"
                                    onClick={(event)=>{
                                        if(!this.state.brandsRender.includes(val)){
                                            this.state.brandsRender.push(val);
                                            event.target.classList.toggle(`${styles.active}`);
                                        }
                                        else {
                                            event.target.classList.toggle(`${styles.active}`);
                                            this.state.brandsRender.splice(this.state.brandsRender.indexOf(val),1);
                                        }
                                    }}>
                                        {val}</button> 
                                    </li>
                                })
                            }
                                </ul>
                    </div>
                }
                <table id="tablecalls" className="table align-items-center justify-content-center mb-0">
                    <thead>
                        <tr>
                            {/* <th className="text-secondary" style={{fontSize:"18px"}}>{t("onlineThServer")}</th> */}
                            <th className="text-uppercase text-sm font-weight-bolder opacity-8 ps-2" 
                            style={{fontSize:"1.8vh", color:"#d3d3d2", fontFamily:"'Ubuntu',sans-serif",textAlign:"center"}}>{t("onlineThTime")}</th>
                            <th className="text-uppercase text-sm font-weight-bolder opacity-8 ps-2" 
                            style={{fontSize:"1.8vh", color:"#d3d3d2", fontFamily:"'Ubuntu',sans-serif"}}>Team</th>
                            <th className="text-uppercase text-sm font-weight-bolder opacity-8 ps-2" 
                            style={{fontSize:"1.8vh", color:"#d3d3d2", fontFamily:"'Ubuntu',sans-serif",textAlign:"center"}}>{t("onlineThSip")}</th>
                            <th className="text-uppercase text-sm font-weight-bolder opacity-8 ps-2" 
                            style={{fontSize:"1.8vh", color:"#d3d3d2", fontFamily:"'Ubuntu',sans-serif",textAlign:"center"}}>Prefix</th>
                            <th className="text-uppercase text-sm font-weight-bolder opacity-8 ps-2" 
                            style={{fontSize:"1.8vh", color:"#d3d3d2", fontFamily:"'Ubuntu',sans-serif",textAlign:"center"}}>{t("onlineThDst")}</th>
                            <th className="text-uppercase text-sm font-weight-bolder opacity-8 ps-2" 
                            style={{fontSize:"1.8vh", color:"#d3d3d2", fontFamily:"'Ubuntu',sans-serif",textAlign:"center"}}>{t("onlineThDuration")}</th>
                            <th className="text-uppercase text-sm font-weight-bolder opacity-8 " 
                            style={{fontSize:"1.8vh", color:"#d3d3d2", fontFamily:"'Ubuntu',sans-serif",paddingRight:"2vw",textAlign:"center"}}>{t("onlineThStatus")}</th>
                            <th className="text-uppercase text-sm font-weight-bolder opacity-8 ps-2" 
                            style={{fontSize:"1.8vh", color:"#d3d3d2", fontFamily:"'Ubuntu',sans-serif",textAlign:"center"}}>{t("onlineThActions")}</th>
                        </tr>
                    </thead>
                    <tbody style={{color:"#d3d3d2"}}>
                            {
                                this.state.names.length>0?this.showTable():null
                            }
                    </tbody>
                </table>
            </div>
    )
    }
}


export default withTranslation()(OnlineTable);
